<template>
  <base-layout :page-title="page.info.group_name" :showBackButton="showSystemBackButton">
    <ion-refresher
      slot="fixed"
      @ionRefresh="doRefresh($event)"
      :disabled="inPostRefreshMode"
    >
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-card
          no-lines
          lines="none"
          class="ion-no-padding ion-no-pargin all-margin-0px"
          style="border-radius: 0px 0px 10px 10px;"
        >
            <ion-img
                v-if="!page.profile_photo.photo"
                src="assets/icon/misc/group.svg"
                @click="showZoomedPhoto(page.profile_photo.photo)"
                style="max-height: 200px; width: 100%;"
              >
            </ion-img>
            <ion-img style="max-height: 200px;" v-if="page.profile_photo.photo" :src="page.profile_photo.photo"></ion-img>
            <ion-button
                @click="takePhoto(page)"
                shape="round"
                size="medium"
                color="medium"
                class="page-update-photo-button"
                v-if="isOwner()"
            >
              <ion-icon color="dark" :icon="icons.cameraOutline" ></ion-icon>
              <ion-spinner v-if="uploadingPhoto" slot="end" name="dots"></ion-spinner>
            </ion-button>
        </ion-card>
        <ion-item no-lines lines="none" class="ion-no-padding ion-no-pargin">
          <ion-label class="left-margin-15px ion-text-wrap" style="margin-bottom: 15px;">
            <h1>{{ page.info.group_name }}</h1>
            <h3>{{ page.info.description }}</h3>
            <ion-button
              fill="clear"
              size="medium"
              style="text-transform: none;"
              class="ion-no-padding ion-no-margin"
              @click="followersVisible = !followersVisible, showInvites=false"
            >
              <span v-if="!followersVisible"><b>{{ page.info.member_count }}</b>&nbsp;Page Followers.</span>
              <span v-if="followersVisible">Hide Followers</span>
            </ion-button>
            <br/> 
            <span v-if="!isOwner() && !followersVisible && !showInvites">
              <ion-button
                fill="clear"
                style="text-transform: none;"
                class="ion-no-padding ion-no-margin"
                @click="followPage(page)"
                v-if="!page.info.is_group_member && !isOwner()"
                :disable="followPageClicked"
              >
                Follow page
              </ion-button>
              <ion-button
                fill="clear"
                class="ion-no-padding ion-no-margin"
                style="text-transform: none;"
                v-if="page.info.is_group_member && !isOwner()"
                @click="unFollowPage(page)"
                :disable="followPageClicked"
              >
                Un-follow
              </ion-button><br/>
              <ion-button
                style="text-transform: none;"
                v-if="page.info.is_group_member && !isOwner()"
                @click="navigateChat(page)"
              >
                Message&nbsp;
                <ion-icon :icon="icons.chatbubbleEllipsesOutline" ></ion-icon>
              </ion-button>
            </span>
            <ion-button
              style="text-transform: none;"
              @click="showInvites=!showInvites, followersVisible=false"
              v-if="!followersVisible && page.info.is_group_member"
            >
              <span v-if="!showInvites">Invite</span>
              <span v-if="showInvites">Hide Invite</span>
            </ion-button>
          </ion-label>
        </ion-item>
        <span v-if="followersVisible && !showInvites">
          <ion-list 
            v-for="member in page.members"
            v-bind:key="member.id"
            style="padding-top: 0px; padding-bottom: 0px;"
          >
            <ion-item class="ion-no-padding ion-no-margin">
              <ion-avatar
                slot="start"
                class="left-margin-15px"
                style="cursor: pointer;"
                @click="navigateUserProfile(member.tokenized_user)"
              >
                <ion-img
                  v-if="member && !member.profile_photo.photo_thumbnail"
                  src="assets/icon/misc/avatar.svg"
                ></ion-img>
                <ion-img
                  size="small"
                  v-if="member && member.profile_photo.photo_thumbnail"
                  :src="member.profile_photo.photo_thumbnail"
                ></ion-img>
              </ion-avatar>
              <ion-label
                style="cursor: pointer"
                @click="navigateUserProfile(member.tokenized_user)"
              >
                <b>{{ member.full_name }}</b>
              </ion-label>
            </ion-item>
          </ion-list>
        </span>
        <span v-if="!followersVisible && showInvites">
          <group-member-invite :group="page" :invitedMembers="invitedMembers"></group-member-invite>
        </span>
        <span v-if="!followersVisible && !showInvites">
          <create-post-form
            style="margin-right: 3px; margin-left: 3px;"
            v-if="isOwner() && !followersVisible"
            @save-post="savePost"
            :restrictSelection="topicsPerPostLimit"
            :topics="createPostTopics"
            :group="page"
            visibilityMode="Group"
            postPlaceHolderMessage="Write a post"
            enableMentions="true"
            :mentionsSuggessionsCallback="getMentionsList"
          ></create-post-form>
          <ion-item>
          <hr style="border-top: 1px solid grey" />
          <ion-label class="left-margin-15px"> <b>Posts</b> </ion-label>
          </ion-item>
          <posts-list
            :posts="posts"
            :fetchPostAPI="listPosts"
            :scrollState="scrollState"
          ></posts-list>
        </span>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  IonIcon,
  IonButton,
  IonCard,
  IonLabel,
  IonItem,
  IonSpinner,
  IonAvatar,
  IonList,
  toastController,
  modalController,
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { homeOutline, cameraOutline, lockClosedOutline, lockOpenOutline, chatbubbleEllipsesOutline } from "ionicons/icons";
import {
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
  processPreview,
  logOffApp,
} from "../services/utils";

import {
  setPosts,
  readFromDB,
} from "../services/db";

import PostImageModal from "../components/posts/PostImageModal.vue";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import ApplyFilterModal from "../components/media/ApplyFilterModal.vue";
import Jimp from "jimp";

const { Camera } = Plugins;


import {
  fetchGroup,
  groupProfilePhoto,
  updateGroupProfilePhoto,
  groupMemberList,
  listPosts,
  requestJoinGroup,
  leaveGroup,
  inviteRequestList,
} from "../services/apiCall";

import { getStrings } from "../services/lang";
import { profileImageSize } from "../services/utils";
import CreatePostForm from "../components/posts/CreatePostForm";
import PostsList from "../components/posts/PostList";
import GroupMemberInvite from "../components/groups/GroupMemberInvite.vue";

export default {
  name: "ProessionalPage",
  ionViewDidEnter() {
    this.profPageLeft = false;
  },
  ionViewDidLeave() {
    this.profPageLeft = true;
  },
  ionViewWillEnter() {
    this.profPageLeft = false;
    this.page.group_id = this.$route.params.pageid;
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    this.profPageLeft = true;
  },
  mounted() {
    this.profPageLeft = false;
    this.page.group_id = this.$route.params.pageid;
  },
  components: {
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonImg,
    IonIcon,
    IonButton,
    IonCard,
    IonLabel,
    IonItem,
    IonSpinner,
    IonAvatar,
    IonList,
    CreatePostForm,
    PostsList,
    GroupMemberInvite,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      isMobileScreen,
      fetchGroup,
      groupProfilePhoto,
      groupMemberList,
      defaultPostsPerFetch,
      getStrings,
      listPosts,
    };
  },
  data() {
    return {
      icons: {
        homeOutline,
        cameraOutline,
        lockClosedOutline,
        lockOpenOutline,
        chatbubbleEllipsesOutline
      },
      page: {
        group_id: "",
        info: {},
        profile_photo: {},
        members: [],
        infoFetched: false,
        inPostRefreshMode: false,
      },
      currentPageProfile: {
        photo: "",
        photo_thumbnail: "",
      },
      scrollState: {
        scrollDisabled: true,
        scrollSkip: 0,
        isFavourite: false,
        topic_filter: [],
        user_id: "",
        group_id: this.$route.params.pageid,
        post_origin_tag: "Group",
      },
      inPostRefreshMode: false,
      refreshEventObject: null,

      profPageLeft: false,
      showSystemBackButton: true,
      uploadingPhoto: false,
      createPostTopics: [],
      topicsPerPostLimit: 2,
      meFollowing: false,
      followPageClicked: false,
      followersVisible: false,
      showInvites: false,
      invitedMembers: [],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    posts() {
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === "Group"
      );
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    doRefresh(ev) {
      this.refreshEventObject = ev;
      this.init();
    },
    isOwner() {
      return this.page.info.created_by === this.user.user_id;
    },
    savePost(postData) {
      this.$store.dispatch("addpost", postData);
    },
    getMentionsList(searchText) {
      if (!searchText || searchText.trim() === "") {
        return this.group.members;
      } else {
        return this.page.members.filter((x) => x.full_name.indexOf(searchText) >= 0);
      }
    },
    readDBAndInit(callbackObject = this) {
      this.$store.dispatch("setposts", []);

      this.page = {
        group_id: this.$route.params.pageid,
        info: {},
        profile_photo: {},
        members: [],
      };

      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    async logOff(callbackObject = this) {
      this.openToast(this.getStrings(this.strings, "LoggingOffUserInvalidSession"));

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    init() {
      // fetch group info
      this.page.infoFetched = false;
      fetchGroup({ group_id: this.page.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            if (res.data.result.group.active_status === "Active") {
              this.page.info = res.data.result.group;
              this.page.infoFetched = true;
            } else {
              this.openToast(this.getStrings(this.strings, "GroupDeletedOrDeactivated"));
              this.router.replace("/posts");
            }
          }
        })
        .catch((err) => {
          console.log("fetchGroups - err", err);
        });

      // fetch group profile photo
      groupProfilePhoto({ group_id: this.page.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.page.profile_photo = res.data.result.group_profile_photo;
          }
        })
        .catch((err) => {
          console.log("GDP  groupProfilePhoto - err", err);
        });

      // fetch group members
      groupMemberList({ group_id: this.page.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.page.members = res.data.result.group_member_list;
          }
        })
        .catch((err) => {
          console.log("groupMemberList - err", err);
        });

      // already invited members
      inviteRequestList({ group_id: this.page.group_id})
        .then((res) => {
          if (res.data.status === 0) {
            this.invitedMembers = res.data.result.invite_list;
          } else {
            console.log("getInvitedMembers - err1");
          }
        }).catch((err) => {
          console.log("getInvitedMembers - err2", err);
        });

      this.fetchGroupPosts();
    },
    async fetchGroupPosts() {
      // fetch group posts
      var cond = {
        filtered_by: "Group",
        group_id: this.page.group_id,
        limit: defaultPostsPerFetch(),
        skip: 0,
      };

      this.page.inPostRefreshMode = true;
      this.$store.dispatch("setposts", []);
      listPosts(cond)
        .then(async (res) => {
          this.scrollState.scrollDisabled = false;
          this.scrollState.scrollSkip = 0;
          this.scrollState.post_origin_tag = "Group";
          this.scrollState.group_id = this.page.group_id;

          if (res.data.status === 0) {
            var processedPosts = res.data.result.post_list.map((onePost) => {
              var previewData = processPreview(onePost);
              onePost["previewData"] = previewData;
              onePost["post_origin_tag"] = "Group";
              return onePost;
            });

            if (!this.profPageLeft) {
              this.$store.dispatch("setposts", processedPosts);
              await setPosts(processedPosts);
            }

            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }

            this.page.inPostRefreshMode = false;
          } else {
            if (this.refreshEventObject !== null) {
              this.refreshEventObject.target.complete();
              this.refreshEventObject = null;
            }

            this.page.inPostRefreshMode = false;
          }
        })
        .catch(async (err) => {
          console.log("listPosts - err", err);

          if (this.refreshEventObject !== null) {
            this.refreshEventObject.target.complete();
            this.refreshEventObject = null;
          }

          this.page.inPostRefreshMode = false;
        });
    },
    async takePhoto(page) {
      try {
        this.uploadingPhoto = true;

        const imgSize = profileImageSize();

        // take the photo
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        // rescale to 300px
        let imageObj = await Jimp.read(photo.webPath);
        let ratio = imgSize / imageObj.getWidth();
        let scaledImgObj = await imageObj.scale(ratio);

        let imgB64 = await scaledImgObj.getBase64Async("image/png");
        this.currentPageProfile.photo = imgB64;

        this.uploadingPhoto = false;

        // filter call back
        var filterCallback = (filteredImg) => {
          this.uploadingPhoto = true;

          this.currentPageProfile.photo = filteredImg;
          this.currentPageProfile.photo_thumbnail = filteredImg;


          updateGroupProfilePhoto({
            group_id: this.page.group_id,
            photo: this.currentPageProfile.photo,
          })
            .then((res) => {
              if (res.data.status === 0) {
                page.profile_photo.photo = this.currentPageProfile.photo;
              }
              this.uploadingPhoto = false;
            })
            .catch((err) => {
              console.log("GPH updateGroupProfilePhoto - err", err);
              this.uploadingPhoto = false;
            });
        };

        // apply filter - dialog
        const modal = await modalController.create({
          component: ApplyFilterModal,
          mode: "md",
        });
        modal.componentProps = {
          imgSrc: this.currentPageProfile.photo,
          parent: modal,
          filter_callback: filterCallback,
        };

        return modal.present();
      } catch (e) {
        this.uploadingPhoto = false;
        console.log("GPH takePhoto - err", e);
      }
    },
    async showZoomedPhoto(pagePhoto) {
      if (!pagePhoto) return;
      try {
        var imgSrc = pagePhoto;
        const modal = await modalController.create({
          component: PostImageModal,
          mode: "md",
        });
        modal.componentProps = {
          title: 'Profile Photo',
          imgSrc: imgSrc,
          parent: modal,
        };
        return modal.present();
      } catch (err) {
        console.log("profile photo err", err);
      }
    },
    followPage(page) {
      this.followPageClicked = true;
      requestJoinGroup({ group_id: page.group_id, }).then((res) => {
        if (res.data.status === 0) {
          if (res.data.result.is_member) {
            page.info.member_count = page.info.member_count + 1;
            page.info.is_group_member = true;
            this.fetchGroupPosts();
          }
        } else {
          this.openToast(res.data.error.error_message);
        }
        this.followPageClicked = false;
      })
      .catch((err) => {
        console.log("Follow page - err", err);
        this.followPageClicked = false;
        this.openToast("Some error while follow this page, please try after some time");
      });
    },
    unFollowPage(page) {
      this.followPageClicked = true;
      this.followersVisible = false;
      this.showInvites = false;
      leaveGroup({ group_id: page.group_id })
        .then((res) => {
          if (res.data.status === 0) {
            page.info.member_count = page.info.member_count - 1;
            page.info.is_group_member = false;
            this.fetchGroupPosts();
            this.openToast("You have un-followed this page");
          } else {
            this.openToast("Some error while un-follow this page, please try after some time");
          }
          this.followPageClicked = false;
        })
        .catch((err) => {
          this.followPageClicked = false;
          console.log("Unfollow page - err", err);
          this.openToast("Some error while un-follow this page, please try after some time");
        });
    },
    async navigateChat(page) {
      await this.router.push("/chat/" + page.info.tokenized_creator);
    },
    async navigateUserProfile(tokenizedUser) {
      await this.router.push("/user/profile/" + tokenizedUser);
    },
  },
};
</script>